import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloudUpload from "@material-ui/icons/CloudUpload";
import FileCopy from "@material-ui/icons/FileCopy";
import Replay from "@material-ui/icons/Replay";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/styles";
import AWS from "aws-sdk";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { v4 as uuidv4 } from "uuid";

const useStyles = (theme) => ({
  imageUpload: {
    maxWidth: "500px",
    maxHeight: "500px",
  },
  preview: {
    marginTop: "10px",
    marginBottom: "10px",
    textAlign: "center",
  },
  url: {
    marginTop: "8px",
    marginBottom: "8px",
    width: "800px",
  },
  button: {
    marginTop: "16px",
    marginBottom: "8px",
  },
  circular: {
    marginTop: "16px",
    marginBottom: "16px",
  },
  caption: {
    marginTop: "8px",
  },
});

function getFileExtension(filename) {
  return (
    filename.substring(filename.lastIndexOf(".") + 1, filename.length) ||
    filename
  );
}

class S3Uploader extends Component {
  state = {
    loader: false,
    file: "",
    previousFileName: "",
    message: "",
    copied: false,
    type_pdf: false,
  };

  componentDidMount() {
    AWS.config.update({
      region: this.props.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.props.IdentityPoolId,
      }),
    });
  }

  onChangeFile = () => {
    this.setState({ loader: true, file: "", type_pdf: false, copied: false });
    const s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: this.props.albumBucketName },
    });
    const { files } = document.getElementById("photoupload");

    if (!files.length) {
      this.setState({ message: "Please choose a file to upload first." });
      return;
    }

    const file = files[0];
    const allowedFileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "application/pdf",
    ];

    if (!allowedFileTypes.includes(file.type)) {
      this.setState({
        message:
          "Please choose a valid file format (JPEG, PNG, SVG, GIF or PDF).",
      });
      return;
    }

    if (file.size > 31_457_280) {
      this.setState({
        message: "The size of your file is > 30MB.",
      });
      return;
    }

    if (file.type === "application/pdf") {
      this.setState({
        type_pdf: true,
      });
    }

    const newFileName = uuidv4() + "." + getFileExtension(file.name);

    s3.upload(
      {
        Key: newFileName,
        Body: file,
        ACL: "private",
        ContentType: file.type,
      },
      (err, data) => {
        if (err) {
          this.setState({
            message:
              "Something went wrong. If you have issues please contact us via support@meetanyway.com",
          });
          return;
        } else {
          const location = this.props.cloudfrontUrl + newFileName;
          this.setState({
            loader: false,
            file: location,
            previousFileName: file.name,
          });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { file, message, loader, type_pdf, previousFileName } = this.state;
    return (
      <>
        {message && (
          <>
            <Alert variant="outlined" severity="error">
              {message}
            </Alert>

            <Button
              startIcon={<Replay />}
              onClick={() => window.location.reload()}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Try again
            </Button>
          </>
        )}
        {file ? (
          <>
            <Box>
              <TextField
                disabled
                className={classes.url}
                id="standard-read-only-input"
                label="File url for map builder"
                defaultValue={file}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box>
              <CopyToClipboard
                text={file}
                onCopy={() => this.setState({ copied: true })}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.button}
                  startIcon={<FileCopy />}
                >
                  Copy File URL
                </Button>
              </CopyToClipboard>
            </Box>
            {type_pdf === false ? (
              <>
                <Typography
                  className={classes.preview}
                  variant="caption"
                  gutterBottom
                >
                  Preview
                </Typography>
                <img
                  className={classes.imageUpload}
                  src={file}
                  alt="imageUpload"
                />
              </>
            ) : (
              <Typography
                className={classes.preview}
                variant="caption"
                gutterBottom
              >
                Upload for <b>{previousFileName}</b> finished successfully.
                <br />
                Please note, we don't create previews for PDF documents here.
              </Typography>
            )}
          </>
        ) : null}

        {!loader ? (
          <>
            {!file && (
              <Typography
                className={classes.headline}
                variant="button"
                gutterBottom
              >
                File Uploader
              </Typography>
            )}
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              component="label"
              startIcon={<CloudUpload />}
            >
              {file ? "Upload Another File" : "Upload File"}
              <input
                id="photoupload"
                type="file"
                style={{ display: "none" }}
                onChange={this.onChangeFile}
              />
            </Button>
            <Typography
              className={classes.caption}
              variant="caption"
              display="block"
              align="center"
              gutterBottom
            >
              Allowed formats: PNG, JPEG, SVG, GIF and PDF.
              <br />
              Max. 30MB. Files stored for 365 days.
            </Typography>
          </>
        ) : (
          <CircularProgress className={classes.circular} />
        )}
      </>
    );
  }
}

S3Uploader.propTypes = {
  buttonName: PropTypes.string,
  bucketRegion: PropTypes.string,
  albumBucketName: PropTypes.string.isRequired,
  IdentityPoolId: PropTypes.string.isRequired,
  cloudfrontUrl: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(S3Uploader);
