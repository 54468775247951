import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import React, { Component } from "react";
import Logo from "./logo.svg";
import S3Uploader from "./S3Uploader";

class App extends Component {
  render() {
    return (
      <>
        <CssBaseline />
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <img src={Logo} alt="Logo" />
          <S3Uploader
            buttonName="Upload File"
            bucketRegion="eu-central-1"
            albumBucketName="meetanyway-uploader"
            cloudfrontUrl="https://d1is8csgywc8q4.cloudfront.net/"
            IdentityPoolId={process.env.REACT_APP_IDENTITY_POOL_ID}
          />
        </Grid>
      </>
    );
  }
}

export default App;
